// const fbOptions = {
//   href: 'https://www.facebook.com/veterinapoprad',
//   width: 500,
//   height: 1000,
//   tabs: 'timeline',
//   hide_cover: false,
//   show_facepile: true,
//   hide_cta: true,
//   small_header: false,
//   adapt_container_width: true,
//   lazy: true,
// };

// <iframe
//   src={`https://www.facebook.com/plugins/page.php?${serializeURI(fbOptions)}`}
//   width="100%"
//   height={fbOptions.height}
// ></iframe>;

import LogoColor from '../assets/logo.svg';
import { openLink } from '../utils';

export default ({ getText }) => {
  return (
    <div className="news">
      <div className="content">
        <p className="news-text">
          {getText('news_text')}
          <a href="https://www.facebook.com/veterinapoprad" target="_blank">
            {getText('news_textlink')}
          </a>
        </p>
        <div className="fb-page" onClick={e => openLink(e, 'https://www.facebook.com/veterinapoprad')}>
          <div className="fb-page-header">
            <img className="fb-page-logo" src={LogoColor} alt="logo" />
            <p className="fb-page-title">
              <span className="page-title">{getText('facebook_pagename')}</span>
              <br />
              {/* magic */}
              {Math.round(351 + (new Date() - new Date(2024, 0, 1)) / 86400000)} {getText('facebook_followers')}
            </p>
          </div>
          <div className="fb-page-controls">
            <div className="fb-page-control">
              <i className="fa fa-facebook-square" />
              <p>{getText('facebook_like')}</p>
            </div>
            <div className="fb-page-control">
              <i className="fa fa-share" />
              <p>{getText('facebook_share')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
