import LogoColor from '../assets/logo.svg';
import DocImg from '../assets/intro_doc.png';
import { scroller } from 'react-scroll';

import { scrollSettings } from '../enums';

export default ({ getText }) => {
  return (
    <div>
      <div className="intro content flex-row">
        <div className="text">
          <img className="logo" src={LogoColor} alt="logo" />
          <h1>{getText('intro_title')}</h1>
          <p>{getText('intro_text')}</p>
          <p className="more" onClick={() => scroller.scrollTo('about', scrollSettings)}>
            {getText('intro_more')}
          </p>
          <div className="button">
            <a href="https://www.veterinarnapohotovostpoprad.sk" target="_blank">
              <i className="fa fa-plus-square" />
              {getText('intro_emergency')}
            </a>
          </div>
          <p className="text-red">{getText('intro_emergencyinfo')}</p>
        </div>
        <div className="intro-img flex-row">
          <img src={DocImg} alt="intro-img" />
        </div>
      </div>
    </div>
  );
};
