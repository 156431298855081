import Logo from '../assets/logo.svg';

import IG01 from '../assets/ig/ig-001.jpg';
import IG02 from '../assets/ig/ig-002.jpg';
import IG03 from '../assets/ig/ig-003.jpg';
import IG04 from '../assets/ig/ig-004.jpg';

import { openLink } from '../utils';

export default ({ getText }) => {
  return (
    <div className="instagram">
      <div className="content">
        <div className="instagram-header flex-row" onClick={e => openLink(e, 'https://www.instagram.com/klinikapoprad')}>
          <img className="logo" src={Logo} alt="logo" />
          <p className="text">@klinikapoprad</p>
        </div>
        <div className="instagram-wrapper flex-row">
          <div className="feed" onClick={e => openLink(e, 'https://www.instagram.com/klinikapoprad')}>
            <img src={IG01} alt="ig01" />
          </div>
          <div className="feed" onClick={e => openLink(e, 'https://www.instagram.com/klinikapoprad')}>
            <img src={IG02} alt="ig02" />
          </div>
          <div className="feed" onClick={e => openLink(e, 'https://www.instagram.com/klinikapoprad')}>
            <img src={IG03} alt="ig03" />
          </div>
          <div className="feed" onClick={e => openLink(e, 'https://www.instagram.com/klinikapoprad')}>
            <img src={IG04} alt="ig04" />
          </div>
        </div>
      </div>
      <p className="follow-link">
        {getText('instagram_followtext')}
        <a href="https://www.instagram.com/klinikapoprad" target="_blank">
          {getText('instagram_followlink')}
        </a>
      </p>
    </div>
  );
};
