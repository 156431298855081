export const languages = {
  SK: 'SK',
  EN: 'EN',
};

export const scrollSettings = {
  duration: 500,
  offset: -52,
  smooth: true,
};

export const keyCookieChecked = 'COOKIE_CHECKED';
export const keyLanguage = 'TRANSLATION';
