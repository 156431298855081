export const openLink = (event, name) => {
  event.preventDefault();
  window.open(name, '_blank');
};

export const scrollToTop = () => window.scrollTo(0, 0);

export const shortcutName = name => {
  const [firstName, lastName] = name.split(' ');
  return `${firstName} ${lastName.charAt(0)}.`;
};

export const nextItem = (items, current) => {
  const currentIndex = items instanceof Array ? items.indexOf(current) : Object.keys(items).indexOf(current);
  const nextIndex = (currentIndex + 1) % (items instanceof Array ? items.length : Object.keys(items).length);
  return items instanceof Array ? items[nextIndex] : items[Object.keys(items)[nextIndex]];
};

export const serializeURI = (obj = {}) =>
  Object.keys(obj)
    .map(key => {
      if (Array.isArray(obj[key])) {
        return obj[key].map(param => `${encodeURIComponent(key)}=${encodeURIComponent(param)}`).join('&');
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join('&');
