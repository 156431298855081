import React, { useState } from 'react';

import { set, get } from '../utils/storage';
import { keyCookieChecked } from '../enums';

export default ({ getText }) => {
  const [hidden, setHidden] = useState(get(keyCookieChecked) === 'true');

  // set(keyCookieChecked, false);

  const handleSubmit = agreed => {
    set(keyCookieChecked, true);
    setHidden(true);
  };

  if (hidden) {
    return <div />;
  }

  return (
    <div className="cookies-bar">
      <div className="content flex-row">
        <p className="cookies-bar-text">
          {getText('cookie_text1')}
          <a href="/nahalkavet_cookies.pdf" target="_blank">
            {getText('cookie_link1')}
          </a>
          {getText('cookie_text2')}
          <a href="/nahalkavet_gdpr.pdf" target="_blank">
            {getText('cookie_link2')}
          </a>
        </p>
        <div className="cookies-bar-button" onClick={() => handleSubmit(true)}>
          {getText('cookie_positive')}
        </div>
        <div className="cookies-bar-button negative" onClick={() => handleSubmit(false)}>
          {getText('cookie_negative')}
        </div>
      </div>
    </div>
  );
};
