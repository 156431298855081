import React from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

import { gPlaceCoord } from '../constants';

export default () => {
  return (
    <div>
      <GoogleMap mapContainerStyle={{ width: '100%', height: '25em' }} center={gPlaceCoord} zoom={15}>
        <MarkerF position={gPlaceCoord} />
      </GoogleMap>
    </div>
  );
};
