export const initialReviews = [
  {
    author_name: 'Martina Zemková',
    rating: 5,
    text: 'Bezkonkurenčná klinika, vždy sa snažia spraviť maximum, aby pomohli. Vďaka nim si môžeme užívať našich dôchodcov Sunnyho a Dallaska plnými dúškami. Ďakujeme. Nech sa darí.',
  },
  {
    author_name: 'Jaroslav Garan',
    rating: 5,
    text: 'Super personál pod vedením skúsených odborníkov. Veľmi ľudský prístup s ktorým sa v tejto dobe už málokde stretneme. Týmto chcem vysloviť poďakovanie p. Dr. Nahalkovi a jeho kolegom, ktorí vyvinuli maximálne úsilie pri záchrane nášho milovaného psika Macka. I keď Macko bol veľký bojovník, prehral boj o život po následnej chirurgickej operácii. Za snahu udržať ho nažive patrí všetkým zamestnancom veľká vďaka. ĎAKUJEME!',
  },
  {
    author_name: 'Daniela Opálená',
    rating: 5,
    text: 'Úžasný prístup veterinárov ku zvieratkám, sú profesionáli a veľmi príjemní. Keby sa aj humánni lekári takto starali o pacientov! Bola som dnes, v nedeľu, pohotovosť plná zvieratiek a Dr Denis Šlosár trpezlivo všetkých dôkladne vyšetril a s Dr. Nahálkom aj operovali. Ďakujem za takú skvelú kliniku, bola som tam prvýkrát z Liptovského Mikuláša. Ďakujem MVDr Šlosár a MVDr Nahálka',
  },
  {
    author_name: 'Renáta Rumanová',
    rating: 5,
    text: 'Ďakujeme p. Mvdr. Bartekovej za pohotovostnú službu na poliklinike , keď sme prišli zo SNV o 23:00 so zdravotnými problémami nášho psíka 16násť ročného yorkšíra Bambyho a ochotou  lekárky prísť z Kežmarku až do Popradu a vyšetriť nášho Bambyho. Ďakujeme ,že ste výnimočná a profesionálna a spoľahlivá klinika s  profesionálnym personálom. V  SNV  v sobotu nefungovala  žiadna veterinárna starostlivosť doobedu a nie je žiadna pohotovosť. Vďaka, že sa môžme tešiť zo zdravia nášho psíka .',
  },
  {
    author_name: 'Andrej Magura',
    rating: 5,
    text: 'Dnešná skúsenosť až nad našé očakávania....Milý personál, Top poradenstvo...Psík zaočkovaný, začipovaný určite odporúčam všetkými 10-tími',
  },
];
