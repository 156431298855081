import React, { useState } from 'react';
import classNames from 'classnames';
import { scroller } from 'react-scroll';

import LogoColor from '../assets/logo.svg';
import LogoBlack from '../assets/logo_stamp.svg';

import { scrollSettings } from '../enums';

export default ({ menuPinned, activeSection, getText }) => {
  const [mobileMenuOpen, openMobileMenu] = useState(false);

  const handleMenuClick = section => {
    openMobileMenu(false);
    scroller.scrollTo(section, scrollSettings);
  };

  return (
    <div className={classNames('menu', { pinned: menuPinned })}>
      <div className="content flex-row">
        <div className="left" onClick={() => handleMenuClick('top')}>
          {menuPinned ? (
            <img className="logo" src={LogoBlack} alt="logo" />
          ) : (
            <img className="logo" src={LogoColor} alt="logo" />
          )}
        </div>
        <div className={classNames('right', { open: mobileMenuOpen })}>
          <i className="fa fa-bars" onClick={() => openMobileMenu(!mobileMenuOpen)} />
          <p className={classNames({ active: activeSection === 'reviews' })} onClick={() => handleMenuClick('reviews')}>
            {getText('menu_reviews')}
          </p>
          <p className={classNames({ active: activeSection === 'news' })} onClick={() => handleMenuClick('news')}>
            {getText('menu_news')}
          </p>
          <p className={classNames({ active: activeSection === 'pricing' })} onClick={() => handleMenuClick('pricing')}>
            {getText('menu_pricing')}
          </p>
          <p className={classNames({ active: activeSection === 'about' })} onClick={() => handleMenuClick('about')}>
            {getText('menu_about')}
          </p>
          <p className={classNames({ active: activeSection === 'contact' })} onClick={() => handleMenuClick('contact')}>
            {getText('menu_contact')}
          </p>
        </div>
      </div>
    </div>
  );
};
