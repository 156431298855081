import { openLink } from '../utils';
import Laboratorium from '../assets/lab.jpg';
import Ortopedia from '../assets/orto.jpg';
import PricingThumbnail from '../assets/pricing.png';
import ServicesThumbnail from '../assets/services.png';

export default ({ getText }) => {
  return (
    <div className="pricing">
      <div className="pricing-wrapper content flex-row">
        <h1>{getText('pricing_header')}</h1>
        <div className="pricing-info flex-row">
          <div className="pricing-thumbnail" onClick={e => openLink(e, '/nahalkavet_sluzby.pdf')}>
            <img src={ServicesThumbnail} alt="pricing thumbnail" />
            <div className="pricing-thumbnail-show">
              <i className="fa fa-download" />
              <p>{getText('pricing_servicespdf')}</p>
            </div>
          </div>
          <div className="pricing-thumbnail" onClick={e => openLink(e, '/nahalkavet_cennik.pdf')}>
            <img src={PricingThumbnail} alt="pricing thumbnail" />
            <div className="pricing-thumbnail-show">
              <i className="fa fa-download" />
              <p>{getText('pricing_pricespdf')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="features content flex-row">
        <div className="feature left">
          <div className="feature-overlay" />
          <p className="feature-name">{getText('pricing_labheader')}</p>
          <p className="feature-text">{getText('pricing_labtext')}</p>
          <img className="feature-photo" src={Laboratorium} />
        </div>
        <div className="feature right">
          <div className="feature-overlay" />
          <p className="feature-name">{getText('pricing_ortoheader')}</p>
          <p className="feature-text">{getText('pricing_ortotext')}</p>
          <img className="feature-photo" src={Ortopedia} />
        </div>
      </div>
    </div>
  );
};
