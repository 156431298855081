import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { isEmpty } from 'lodash';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import { captchaApiKey, emailServiceId, emailTemplateId, emailApiKey } from '../constants';

const validationSchema = yup.object().shape({
  name: yup.string().typeError('*').required('*'),
  email: yup.string().email('*').required('*'),
  message: yup.string().typeError('*').required('*'),
});

export default ({ getText }) => {
  const [formMessage, setFormMessage] = useState('');
  const [captchaValid, setCaptchaValid] = useState(false);

  const submitForm = (values, actions) => {
    emailjs
      .send(
        emailServiceId,
        emailTemplateId,
        {
          sender_name: values.name,
          sender_email: values.email,
          message: values.message,
        },
        emailApiKey,
      )
      .then(
        result => {
          setFormMessage(JSON.stringify(result));
        },
        error => {
          setFormMessage(JSON.stringify(error));
        },
      );

    actions.resetForm();
    setCaptchaValid(false);
  };

  return (
    <div className="contact">
      <div className="content">
        <div className="contact-tabs flex-row">
          <div className="contact-tab">
            <i className="fa fa-map-marker" />
            <p className="contact-tab-header">{getText('contact_addressheader')}</p>
            <p>Hraničná 666/12</p>
            <p>059 01 Poprad</p>
          </div>
          <div className="contact-tab">
            <i className="fa fa-clock-o" />
            <p className="contact-tab-header">{getText('contact_scheduleheader')}</p>
            <p>{getText('contact_scheduleweek')} 8:00-18:00</p>
            <p>{getText('contact_scheduleweekend')} 9:00-12:00</p>
            <a href="https://docs.google.com/document/d/1O_Sa6s0MQToBy1ZuitnhXVg0q9bPpANMW_egIypM3Uc/edit?usp=sharing" target="_blank">{getText('contact_schedulelink')}</a>
          </div>
          <div className="contact-tab">
            <i className="fa fa-comment" />
            <p className="contact-tab-header">{getText('contact_connectionheader')}</p>
            <p>0911 761 664</p>
            <p>info@veterinapoprad.sk</p>
            <p>www.veterinapoprad.sk</p>
          </div>
        </div>
        <div className="contact-form flex-col">
          <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => submitForm(values, actions)}
          >
            {({ errors, touched }) => (
              <Form className="contact-form-wrapper flex-row">
                <div className="contact-form-left flex-col">
                  <div className="contact-form-field">
                    <Field id="name" name="name" placeholder={getText('contact_form_name')} />
                    {errors.name && touched.name && <span className="field-error">{errors.name}</span>}
                  </div>
                  <div className="contact-form-field">
                    <Field id="email" name="email" placeholder={getText('contact_form_email')} type="email" />
                    {errors.email && touched.email && <span className="field-error">{errors.email}</span>}
                  </div>
                </div>
                <div className="contact-form-right flex-col">
                  <div className="contact-form-field">
                    <Field
                      className="textarea"
                      id="message"
                      name="message"
                      placeholder={getText('contact_form_text')}
                      component="textarea"
                    />
                    {errors.message && touched.message && <span className="field-error">{errors.message}</span>}
                  </div>
                </div>
                <div className="contact-form-controls flex-row">
                  <div className="form-captcha flex-row">
                    <ReCAPTCHA className="recaptcha" sitekey={captchaApiKey} onChange={() => setCaptchaValid(true)} />
                    <p className="recaptcha-disclaimer">
                      * By selecting 'Send' you are agreeing to Google reCAPTCHA Privacy Policy & Terms and Conditions
                    </p>
                  </div>
                  <button className="submit-button" type="submit" disabled={!captchaValid || !isEmpty(errors)}>
                    {getText('contact_form_submit')}
                  </button>
                  <p className="submit-message">{formMessage}</p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
