import React from 'react';
import Slider from 'react-slick';
import { map } from 'lodash';

import Photo001 from '../assets/team/team-001.jpg';
import Photo002 from '../assets/team/team-002.jpg';
import Photo003 from '../assets/team/team-003.jpg';
import Photo011 from '../assets/team/team-011.jpg';
import Photo012 from '../assets/team/team-012.jpg';
import Photo017 from '../assets/team/team-017.jpg';
import Photo023 from '../assets/team/team-023.jpg';
import Photo032 from '../assets/team/team-032.jpg';
import Photo040 from '../assets/team/team-040.jpg';
import Photo076 from '../assets/team/team-076.jpg';

const teamPhotos = {
  photo001: Photo001,
  photo002: Photo002,
  photo003: Photo003,
  photo011: Photo011,
  photo012: Photo012,
  photo017: Photo017,
  photo023: Photo023,
  photo032: Photo032,
  photo040: Photo040,
  photo076: Photo076,
};

const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 5,
  slidesToScroll: 4,
  responsive: [
    { breakpoint: 1300, settings: { slidesToShow: 4, slidesToScroll: 3 } },
    { breakpoint: 1000, settings: { slidesToShow: 3, slidesToScroll: 2 } },
    { breakpoint: 800, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    { breakpoint: 550, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  ],
};

export default ({ getText }) => {
  return (
    <div>
      <div className="team content">
        <h1 className="team-header">{getText('team_header')}</h1>
        <p className="team-text">{getText('team_text')}</p>
        <Slider className="team-members" {...sliderSettings}>
          {map(getText('team_members'), (member, i) => (
            <div key={`mem${i}`} className="team-member">
              <p className="team-member-name">{member.name}</p>
              <p className="team-member-text">{member.about}</p>
              <div className="team-member-circle">
                <div className="team-member-overlay" />
                <img className="team-member-photo" src={teamPhotos[member.photo]} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
