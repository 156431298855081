import React from 'react';
import { map } from 'lodash';

import Device001 from '../assets/devices/device001.jpg';
import Device002 from '../assets/devices/device002.jpg';
import Device003 from '../assets/devices/device003.jpg';
import Device004 from '../assets/devices/device004.jpg';
import Device005 from '../assets/devices/device005.jpg';
import Device006 from '../assets/devices/device006.jpg';
import Device007 from '../assets/devices/device007.jpg';
import Device008 from '../assets/devices/device008.jpg';
import Device009 from '../assets/devices/device009.jpg';
import Device010 from '../assets/devices/device010.jpg';
import Device011 from '../assets/devices/device011.jpg';
import Device012 from '../assets/devices/device012.jpg';
import Device013 from '../assets/devices/device013.jpg';
import Device014 from '../assets/devices/device014.jpg';
import Device015 from '../assets/devices/device015.jpg';
import Device016 from '../assets/devices/device016.jpg';
import Device017 from '../assets/devices/device017.jpg';
import Device018 from '../assets/devices/device018.jpg';
import Device019 from '../assets/devices/device019.jpg';
import Device020 from '../assets/devices/device020.jpg';
import Device021 from '../assets/devices/device021.jpg';
import Device022 from '../assets/devices/device022.jpg';
import Device023 from '../assets/devices/device023.jpg';
import Device024 from '../assets/devices/device024.jpg';

const devicesPhotos = {
  device001: Device001,
  device002: Device002,
  device003: Device003,
  device004: Device004,
  device005: Device005,
  device006: Device006,
  device007: Device007,
  device008: Device008,
  device009: Device009,
  device010: Device010,
  device011: Device011,
  device012: Device012,
  device013: Device013,
  device014: Device014,
  device015: Device015,
  device016: Device016,
  device017: Device017,
  device018: Device018,
  device019: Device019,
  device020: Device020,
  device021: Device021,
  device022: Device022,
  device023: Device023,
  device024: Device024,
};

export default ({ getText }) => {
  return (
    <div className="devices">
      <div className="content">
        <div className="devices-wrapper">
          {map(getText('devices'), (device, i) => (
            <div key={`dev${i}`} className="device">
              <span className="device-text">
                <b>{device.name}</b>
                <br />
                {device.description}
              </span>
              <img className="device-image" src={devicesPhotos[device.image]} />
            </div>
          ))}
        </div>
        <p className="devices-text">{getText('devices_text')}</p>
      </div>
    </div>
  );
};
