import { scroller } from 'react-scroll';

import { scrollSettings } from '../enums';

export default ({ getText }) => {
  return (
    <div className="header-banner">
      <div className="header-overlay">
        <div className="content flex-col">
          <h1>
            {getText('headerbanner_title1')}
            <br />
            {getText('headerbanner_title2')}
          </h1>
          <div className="button">
            <a href="tel:+421911761664">
              <i className="fa fa-phone" />
              0911 761 664
            </a>
          </div>
          <p onClick={() => scroller.scrollTo('contact', scrollSettings)}>Hraničná 666/12 058 01 Poprad</p>
        </div>
      </div>
    </div>
  );
};
