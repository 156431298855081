import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { map, truncate } from 'lodash';

import { shortcutName } from '../utils';
import { gPlaceId } from '../constants';
import { initialReviews } from '../enums/reviews';

const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default ({ getText }) => {
  const [reviews, setReviews] = useState(initialReviews);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

        placesService.getDetails(
          {
            placeId: gPlaceId,
            fields: ['name', 'rating', 'reviews'],
          },
          (place, status) => {
            if (status === 'OK') {
              setReviews(place.reviews);
            } else {
              console.error('Error fetching place details:', status);
            }
          },
        );
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);

  return (
    <div className="greviews">
      <div className="content">
        <Slider className="slider" {...sliderSettings}>
          {map(reviews, (review, i) => {
            if (review.rating == 5) {
              return (
                <div className="review" key={`rev${i}`}>
                  <i className="review-text">
                    "
                    {truncate(review.text, {
                      length: 500,
                    })}
                    "
                  </i>
                  <p className="review-author">
                    - {shortcutName(review.author_name)} -
                    <br />
                    {getText('reviews_info')}
                  </p>
                </div>
              );
            }
          })}
        </Slider>
      </div>
    </div>
  );
};
