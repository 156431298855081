import React, { useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { Waypoint } from 'react-waypoint';
import { Element } from 'react-scroll';
import { get } from 'lodash';

import Preloader from './components/Preloader';
import Menu from './components/Menu';
import HeaderBar from './components/HeaderBar';
import HeaderBanner from './components/HeaderBanner';
import Intro from './components/Intro';
import TeamBanner from './components/TeamBanner';
import FBNews from './components/FBNews';
import Pricing from './components/Pricing';
import GReviews from './components/GReviews';
import Team from './components/Team';
import Devices from './components/Devices';
import IgFeed from './components/IgFeed';
import Contact from './components/Contact';
import GMap from './components/GMap';
import Footer from './components/Footer';
import CookiesBar from './components/CookiesBar';

import { gApiKey, gLibs } from './constants';
import { languages, keyLanguage } from './enums';
import { skTexts, enTexts } from './enums/lang';
import { get as getLocalStorage } from './utils/storage';

export default () => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [language, selectLanguage] = useState(getLocalStorage(keyLanguage) || languages.SK);
  const [menuPinned, pinMenu] = useState(false);
  const [activeSection, setActiveSection] = useState(''); // TODO toto nefunguje inverzne

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: gApiKey,
    libraries: gLibs,
  });

  useEffect(() => {
    // TODO prerender images

    const timeoutId = setTimeout(() => {
      setPageLoaded(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!pageLoaded || !isLoaded) {
    return <Preloader pageLoaded={pageLoaded} />;
  }

  const getText = selector => {
    if (language === languages.SK) {
      return get(skTexts, selector, '-- NO TEXT ?! --');
    } else if (language === languages.EN) {
      return get(enTexts, selector, '-- NO TEXT ?! --');
    }
  };

  return (
    <div>
      <Element name="top" />
      <Menu menuPinned={menuPinned} activeSection={activeSection} getText={getText} />
      <Waypoint onPositionChange={e => pinMenu(e.currentPosition === 'above')} topOffset={-50} />

      <CookiesBar getText={getText} />

      <HeaderBar language={language} selectLanguage={selectLanguage} getText={getText} />
      <HeaderBanner getText={getText} />

      <Intro getText={getText} />

      <Element name="reviews" />
      <GReviews isLoaded={isLoaded} getText={getText} />

      <Element name="news" />
      <FBNews getText={getText} />

      <IgFeed getText={getText} />

      <TeamBanner />

      <Element name="pricing" />
      <Pricing getText={getText} />

      <Element name="about" />
      <Team getText={getText} />
      <Devices getText={getText} />

      <Element name="contact" />
      <Contact getText={getText} />
      <GMap />

      <Footer getText={getText} />
    </div>
  );
};
