import SponsorRoyalCanin from '../assets/sponsor_royal_canin.svg';

export default ({ getText }) => {
  return (
    <div className="footer">
      <div className="content">
        <div className="flex-row">
          <div className="flex-col">
            <img className="sponsor" src={SponsorRoyalCanin} alt="sponsor" />
            <a href="/nahalkavet_cookies.pdf" target="_blank">
              {getText('cookie_link1')}
            </a>
            <a href="/nahalkavet_gdpr.pdf" target="_blank">
              {getText('cookie_link2')}
            </a>
          </div>
          <div className="flex-col">
            <b>{getText('footer_contactheader')}</b>
            <p>info@veterinapoprad.sk</p>
            <p>0911 761 664</p>
            <p>052 776 1664</p>
            <p>
              <a href="https://www.facebook.com/veterinapoprad" target="_blank">
                <i className="fa fa-facebook-square" />
              </a>
              <a href="https://www.instagram.com/klinikapoprad" target="_blank">
                <i className="fa fa-instagram" />
              </a>
            </p>
          </div>
          <div className="flex-col">
            <b>{getText('footer_addressheader')}</b>
            <p>{getText('footer_addresstext1')}</p>
            <p>Hraničná 666/12</p>
            <p>058 01 Poprad</p>
          </div>
          <div className="flex-col">
            <b>{getText('footer_scheduleheader')}</b>
            <p>{getText('footer_scheduleweek')}</p>
            <p>8:00 - 18:00</p>
            <p>{getText('footer_scheduleweekend')}</p>
            <p>9:00 - 12:00</p>
            <a href="https://docs.google.com/document/d/1O_Sa6s0MQToBy1ZuitnhXVg0q9bPpANMW_egIypM3Uc/edit?usp=sharing" target="_blank">{getText('footer_schedulelink')}</a>
          </div>
        </div>
        <div className="copyright flex-row">
          <p>© 2024 coded in ReactJS</p>
        </div>
      </div>
    </div>
  );
};
