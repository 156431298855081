export const fbAppId = '270525819049651';

export const gApiKey = 'AIzaSyBAcCEjl41_uqaucif2_aRVgAilvC4viCg';
export const gPlaceId = 'ChIJDWatDbg6PkcRCt1s2PetYzY';
export const gPlaceCoord = { lat: 49.0598901, lng: 20.2830594 };
export const gLibs = ['places'];

export const emailServiceId = 'service_4kvsoda';
export const emailTemplateId = 'template_3856ifp';
export const emailApiKey = 'sfmOFGjZuydRrbO_s';

export const captchaApiKey = '6LeSQS8pAAAAAD5kva85X7LE_RYMASkGi_6emnkw';
