import { nextItem } from '../utils';
import { set as setLocalStorage } from '../utils/storage';
import { languages, keyLanguage } from '../enums';

import IconLangSk from '../assets/lang_sk.png';
import IconLangEn from '../assets/lang_en.png';

const langIcons = {
  SK: IconLangSk,
  EN: IconLangEn,
};

export default ({ language, selectLanguage, getText }) => {
  const nextLanguage = nextItem(languages, language);
  const langIcon = langIcons[nextLanguage];

  const handleSelectLanguage = nextLanguage => {
    selectLanguage(nextLanguage);
    setLocalStorage(keyLanguage, nextLanguage);
  };

  return (
    <div className="header-bar">
      <div className="content flex-row">
        <div className="left">
          <p>{getText('headerbar_left')}</p>
        </div>
        <div className="right flex-row">
          <a href="tel:+421911761664">
            <i className="fa fa-phone" />
            0911 761 664
          </a>
          <a href="mailto:info@veterinapoprad.sk">
            <i className="fa fa-envelope" />
            info@veterinapoprad.sk
          </a>
          <p className="social">
            <a href="https://www.facebook.com/veterinapoprad" target="_blank">
              <i className="fa fa-facebook-square" />
            </a>
            <a href="https://www.instagram.com/klinikapoprad" target="_blank">
              <i className="fa fa-instagram" />
            </a>
            <img className="lang" src={langIcon} alt="lang" onClick={() => handleSelectLanguage(nextLanguage)} />
          </p>
        </div>
      </div>
    </div>
  );
};
